export default {
  APP: "APP",
  OPTIONS: "OPTIONS",
  RESELLERS: "RESELLERS",
  PACKAGES: "PACKAGES",
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  RESELLER_PRODUCTS: "RESELLER_PRODUCTS",
  RESELLER_SERVICES: "RESELLER_SERVICES",
  RESELLER_INVOICES: "RESELLER_INVOICES",
  RESELLER_PAYMENTS: "RESELLER_PAYMENTS",
  IMPORTS: "IMPORTS",
  CUSTOMERS: "CUSTOMERS",
  SUPPLIERS: "SUPPLIERS",
  CONTACTS: "CONTACTS",
  PRODUCTS: "PRODUCTS",
  FILES: "FILES",
  FOLDERS: "FOLDERS",
  LOCATIONS: "LOCATIONS",
  ORGANIZATIONS: "ORGANIZATIONS",
  USERS: "USERS",
  ROLES: "ROLES",
  PERMISSIONS: "PERMISSIONS",
  LOGS: "LOGS",
  PURCHASES_ORDERS: "PURCHASES_ORDERS",
  PURCHASES_INVOICES: "PURCHASES_INVOICES",
  PURCHASES_DELIVERIES: "PURCHASES_DELIVERIES",
  PURCHASES_PAYMENTS: "PURCHASES_PAYMENTS",
  SALES_ORDERS: "SALES_ORDERS",
  SALES_INVOICES: "SALES_INVOICES",
  SALES_DELIVERIES: "SALES_DELIVERIES",
  SALES_PAYMENTS: "SALES_PAYMENTS",
  WAREHOUSES: "WAREHOUSES",
  STOCK_MOVEMENTS: "STOCK_MOVEMENTS",
  REQUESTS: "REQUESTS",
  VEHICLES: "VEHICLES",
  VEHICLE_ESTIMATIONS: "VEHICLE_ESTIMATIONS",
  INSPECTION_FORMS: "INSPECTION_FORMS",
  INSPECTIONS: "INSPECTIONS",
  ESTIMATIONS: "ESTIMATIONS",
  REQUEST_LOGS: "REQUEST_LOGS",
  COMMENTS: "COMMENTS",
  TAXES: "TAXES",
};
