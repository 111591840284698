<template>
  <div class="elite-tabs-wrapper-content">
    <vehicle-ref-standardized
      :vehicle="vehicle"
      @onEditVehicle="$emit('onEditVehicle')"
    />
    <!-- <slot name="header">
      <h3>{{ $objectDenomination(vehicle) }}</h3>
    </slot> -->
    <div class="col-12">
      <dl class="row" v-if="false">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATION") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="vehicle.allowedLocations" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SKU_NUMBER") }}</dt>
        <dd class="col-sm-8">
          <copy-element :element="vehicle.sku" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.VIN") }}</dt>
        <dd class="col-sm-8">
          <copy-element :element="vehicle.vin" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL_YEAR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model_year }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MAKE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.make }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.MODEL") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.model ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.SERIES") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.series ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.STYLE") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.style ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.ENGINE_DESCRIPTION") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.engine_description ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.FUEL_TYPE") }}</dt>
        <dd class="col-sm-8">
          {{
            vehicle.fuel_type
              ? $t(`FUEL_TYPES.${vehicle.fuel_type.toUpperCase()}`)
              : "-"
          }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.CLASS_NAME") }}</dt>
        <dd class="col-sm-8">
          {{
            $te(`VEHICLES_CLASSES.VEHICLE_${vehicle.class_code}`)
              ? $t(`VEHICLES_CLASSES.VEHICLE_${vehicle.class_code}`)
              : vehicle.class_code
          }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.REGISTRATION_NUMBER") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.registration_number ?? "-" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("VEHICLES.COLOR") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.color ?? "-" }}
        </dd>
      </dl>

      <dl class="row" v-if="!isNaN(vehicle.odometer)">
        <dt class="col-sm-4">{{ $t("VEHICLES.ODOMETER") }}</dt>
        <dd class="col-sm-8">
          {{ vehicle.odometer }} {{ vehicle.odometer_unit }}
        </dd>
      </dl>

      <dl class="row" v-if="false">
        <dt class="col-sm-4">{{ $t("VEHICLES.TRANSMISSION") }}</dt>
        <dd class="col-sm-8">
          {{
            $te(`TRANSMISSIONS.${vehicle.transmission?.toUpperCase()}`)
              ? $t(`TRANSMISSIONS.${vehicle.transmission?.toUpperCase()}`)
              : vehicle.transmission
          }}
        </dd>
      </dl>

      <dl class="row" v-if="false">
        <dt class="col-sm-4">{{ $t("VEHICLES.DRIVETRAIN") }}</dt>
        <dd class="col-sm-8">
          {{
            $te(`DRIVETRAIN.DRIVETRAIN_${vehicle.drivetrain}`)
              ? $t(`DRIVETRAIN.DRIVETRAIN_${vehicle.drivetrain}`)
              : vehicle.drivetrain
          }}
        </dd>
      </dl>

      <dl class="row" v-if="false && vehicle.customer">
        <dt class="col-sm-4">{{ $t("COMMON.CUSTOMER") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="vehicle.customer" />
        </dd>
      </dl>

      <dl class="row" v-if="false && vehicle.mecanic">
        <dt class="col-sm-4">{{ $t("COMMON.MECANIC") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="vehicle.mecanic" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <object-link :object="vehicle.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.created_at">
          {{ $timeZoneDateFormat(vehicle.created_at) }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8" v-if="vehicle.updated_at">
          {{ $timeZoneDateFormat(vehicle.updated_at) }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import CopyElement from "@/components/CopyElement.vue";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  name: "vehicle-view-global",

  components: { CopyElement, VehicleRefStandardized },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
