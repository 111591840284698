<template>
  <div class="list-table-inner">
    <div class="list-table-inner-header">
      <div class="search">
        <div
          v-if="$currentUserCan($permissions.PERM_CREATE_ESTIMATIONS)"
          class="search-add"
        >
          <base-button class="add" icon size="sm" @click="addEstimate">
            <span class="btn-inner--icon">
              <i class="far fa-plus-circle"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("ESTIMATES.ADD_ESTIMATE") }}
            </span>
          </base-button>
        </div>
        <div class="search-form">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fal fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <button
            @click="
              () => {
                showAdvancedFilter = !showAdvancedFilter;
              }
            "
          >
            <i class="far fa-sliders-h"></i>
          </button>
        </div>

        <notification-subscription
          v-if="$currentUserCan($permissions.PERM_VIEW_ESTIMATIONS)"
          :objectType="'estimations'"
          :events="{
            CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
            UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
            DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
          }"
        />
      </div>
      <transition name="slide">
        <div class="advanced-search" v-if="showAdvancedFilter">
          <div class="advanced-search-item pagination-select">
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="advanced-search-item">
            <el-select class="select-primary" v-model="selectedStatus">
              <el-option
                class="select-primary"
                key="0"
                :label="$t('COMMON.STATUS') + '...'"
                :value="null"
              />
              <el-option
                v-for="value in estimateStatusesOption"
                class="select-primary"
                :key="value"
                :label="$t(`ESTIMATES.ESTIMATE_STATUS_${value}`)"
                :value="value"
              />
            </el-select>
          </div>
          <div class="advanced-search-item date">
            <date-range-selector
              @onDateRangeChanged="
                (dates) => {
                  selectedDateRange = dates;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.REVIEWED_BY')"
              @userChanged="(userId) => (selectedReviewedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_USERS)"
          >
            <user-selector
              :placeholder="$t('ESTIMATES.COMPLETED_BY')"
              @userChanged="(userId) => (selectedCompletedBy = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCreator &&
              $currentUserCan($permissions.PERM_VIEW_ANY_USERS)
            "
          >
            <user-selector
              :placeholder="$t('ESTIMATES.CREATOR')"
              @userChanged="(userId) => (selectedCreator = userId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterCustomer &&
              $currentUserCan($permissions.PERM_VIEW_ANY_CUSTOMERS)
            "
          >
            <customer-selector
              :disabled="false"
              @customerChanged="(customerId) => (selectedCustomer = customerId)"
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <year-selector
              :filterable="true"
              :value="selectedVehicleModelYear"
              allow-none
              @yearChanged="
                (year) => {
                  selectedVehicleModelYear = year;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <make-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :placeholder="$t('VEHICLES.MAKE')"
              :value="selectedVehicleMake"
              @makeChanged="
                (make) => {
                  selectedVehicleMake = make;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <model-selector
              allow-none
              :year="selectedVehicleModelYear"
              :filterable="true"
              :make="selectedVehicleMake"
              :value="selectedVehicleModel"
              @modelChanged="
                (model) => {
                  selectedVehicleModel = model;
                }
              "
            />
          </div>
          <div
            class="advanced-search-item"
            v-if="
              !filterVehicle &&
              $currentUserCan($permissions.PERM_VIEW_ANY_VEHICLES)
            "
          >
            <serie-selector
              allow-none
              :year="selectedVehicleModelYear"
              :model="selectedVehicleModel"
              :filterable="true"
              :value="selectedVehicleSeries"
              @serieChanged="
                (serie) => {
                  selectedVehicleSeries = serie;
                }
              "
            />
          </div>
          <div
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            class="advanced-search-item"
          >
            <organization-selector
              @organizationChanged="
                (organizationId) => (selectedOrganization = organizationId)
              "
            />
          </div>
          <div
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.LOCATION')}`">
              <location-selector
                @locationChanged="(location) => (selectedLocation = location)"
              />
            </base-input>
          </div>
          <div
            v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INSPECTIONS)"
            class="advanced-search-item"
          >
            <base-input :placeholder="`${$t('COMMON.INSPECTION')}`">
              <inspection-selector
                @inspectionChanged="
                  (inspection) => (selectedInspection = inspection)
                "
              />
            </base-input>
          </div>
          <div class="advanced-search-item update">
            <base-button class="add" icon size="sm">
              <span class="btn-inner--icon">
                <i class="far fa-sync-alt"></i>
              </span>
              <span class="btn-inner--text"> {{ $t("COMMON.UPDATE") }} </span>
            </base-button>
          </div>
        </div>
      </transition>
    </div>

    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush estimates"
          header-row-class-name="thead-light"
          :data="estimations"
          @sort-change="sortChange"
          row-class-name="cursor-pointer"
          @row-click="
            (row) => {
              viewEstimate(row);
            }
          "
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" />
          </div>
          <el-table-column width="75px">
            <template v-slot="{ row }">
              <div class="image" v-if="row.vehicle">
                <img
                  v-if="row.vehicle.gallery?.length > 0"
                  :src="row.vehicle.gallery[0]"
                  class="avatar rounded-circle mr-3"
                />
                <span class="default-icon">
                  <i class="far fa-camera"></i>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('ESTIMATES.CODE')"
            prop="code"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              {{ $objectDenomination(row) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            sortable="custom"
            min-width="230"
          >
            <template v-slot="{ row }">
              <span>
                <estimate-status-badge :status="row.status" :estimate="row" />
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.SKU')"
            prop="vehicle_sku"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <copy-element :element="row?.vehicle?.sku" />
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.SERIES')"
            prop="vehicle_series"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              <span>{{ row?.vehicle?.series }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.MODEL_YEAR')"
            prop="vehicle_year"
            sortable="custom"
            min-width="100"
          >
            <template v-slot="{ row }">
              <span>{{ row?.vehicle?.model_year }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.ODOMETER')"
            prop="vehicle_make"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <span>
                {{ row.odometer ?? row.vehicle?.odometer }}
                {{ row.vehicle?.odometer_unit }}
              </span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.MAKE')"
            prop="vehicle_make"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <span>{{ row?.vehicle?.make }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.MODEL')"
            prop="vehicle_model"
            sortable="custom"
            min-width="150"
          >
            <template v-slot="{ row }">
              <span>{{ row?.vehicle?.model }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('INSPECTIONS.COMPLETED_BY')"
            min-width="220"
          >
            <template v-slot="{ row }">
              <object-link
                :object="row.completedBy"
                v-if="$currentUserCan($permissions.PERM_VIEW_USERS)"
              />
              <span v-else>{{ $objectDenomination(row.completedBy) }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('ESTIMATES.UPDATED_AT')"
            prop="updated_at"
            sortable="custom"
            min-width="240"
          >
            <template v-slot="{ row }">
              {{ $timeZoneDateFormat(row.updated_at, "L LT") }}
            </template>
          </el-table-column>

          <el-table-column
            :fixed="$listActionsButtonsPosition()"
            min-width="120"
          >
            <template v-slot="{ row }">
              <div class="table-actions">
                <el-tooltip :content="$t('COMMON.VIEW')" placement="top">
                  <a
                    type="text"
                    class="table-action view"
                    data-toggle="tooltip"
                    @click="viewEstimate(row)"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  :class="{
                    disabled: !canDeleteEstimate(row),
                  }"
                >
                  <a
                    type="text"
                    class="table-action delete"
                    data-toggle="tooltip"
                    @click="deleteEstimate(row)"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div class="list-table-inner-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}
        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import estimatePermissionsMixin from "@/mixins/estimate-permissions-mixin";
import {
  estimateStatusesOption,
  ESTIMATE_STATUS_DRAFT,
  ESTIMATE_INCLUDES,
} from "@/constants/estimates";
import { BasePagination } from "@/components";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import DateRangeSelector from "@/components/DateRangeSelector.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import UserSelector from "@/components/UserSelector.vue";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationSelector from "@/components/LocationSelector.vue";
import EstimateStatusBadge from "./EstimateStatusBadge.vue";
import InspectionSelector from "@/components/InspectionSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import MakeSelector from "@/components/MakeSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import CopyElement from "@/components/CopyElement.vue";

export default {
  name: "estimate-list-table",

  components: {
    BasePagination,
    NotificationSubscription,
    DateRangeSelector,
    CustomerSelector,
    OrganizationSelector,
    LocationSelector,
    UserSelector,
    InspectionSelector,
    EstimateStatusBadge,
    YearSelector,
    MakeSelector,
    ModelSelector,
    SerieSelector,
    CopyElement,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin, estimatePermissionsMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "organization id",
    },
    filterCustomer: {
      type: String,
      default: null,
      description: "customer id",
    },
    filterStatus: {
      type: String,
      default: null,
      description: "Status",
    },
    filterCreator: {
      type: String,
      default: null,
      description: "Creator",
    },
    filterVehicle: {
      type: String,
      default: null,
      description: "Vehicle id",
    },
    filterInspection: {
      type: String,
      default: null,
      description: "Inspection id",
    },
  },

  data() {
    return {
      query: null,
      showAdvancedFilter: false,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      estimations: [],
      loading: true,
      selectedEstimationStatus: null,
      selectedStatus: null,
      estimateStatusesOption,
      selectedDateRange: null,
      selectedInspection: null,
      selectedCustomer: null,
      selectedLocation: null,
      selectedCreator: null,
      selectedOrganization: null,
      selectedCompletedBy: null,
      selectedVehicle: null,
      selectedReviewedBy: null,
      ESTIMATE_STATUS_DRAFT,
      selectedVehicleModelYear: null,
      selectedVehicleMake: null,
      selectedVehicleModel: null,
      selectedVehicleSeries: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedInspection: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterCustomer: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCustomer: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterInspection: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocation: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedDateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    filterStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterCreator: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCreator: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterVehicle: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedVehicleModelYear: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedVehicleMake: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedVehicleModel: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedVehicleSeries: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCompletedBy: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedReviewedBy: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      this.estimations = [];
      this.loading = true;

      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {},
          ...(this.query ? { search: this.query } : {}),
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: ESTIMATE_INCLUDES.join(","),
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.filterCustomer) {
          params = {
            ...params,
            filter: { ...params.filter, customer: this.filterCustomer },
          };
        }
        if (this.selectedCustomer) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedCustomer,
            },
          };
        }
        if (this.selectedInspection) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              inspection: this.selectedInspection,
            },
          };
        }

        if (this.filterInspection) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              inspection: this.filterInspection,
            },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.selectedDateRange) {
          if (this.selectedDateRange.length == 2) {
            params = {
              ...params,
              filter: {
                ...params.filter,
                created_at: [
                  this.selectedDateRange[0] + " 00:00:00",
                  this.selectedDateRange[1] + " 23:59:59",
                ],
              },
            };
          }
        }
        if (this.filterStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.filterStatus,
            },
          };
        }
        if (this.selectedStatus) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.selectedStatus,
            },
          };
        }
        if (this.filterCreator) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              creator: this.filterCreator,
            },
          };
        }
        if (this.selectedCreator) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              creator: this.selectedCreator,
            },
          };
        }
        if (this.filterVehicle) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: this.filterVehicle,
            },
          };
        }

        if (this.selectedVehicleModelYear) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: {
                ...params.filter?.vehicle,
                model_year: this.selectedVehicleModelYear,
              },
            },
          };
        }

        if (this.selectedVehicleMake) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: {
                ...params.filter?.vehicle,
                make: this.selectedVehicleMake,
              },
            },
          };
        }

        if (this.selectedVehicleModel) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: {
                ...params.filter?.vehicle,
                model: this.selectedVehicleModel,
              },
            },
          };
        }

        if (this.selectedVehicleSeries) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              vehicle: {
                ...params.filter?.vehicle,
                series: this.selectedVehicleSeries,
              },
            },
          };
        }

        if (this.selectedCompletedBy) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              completedBy: this.selectedCompletedBy,
            },
          };
        }
        if (this.selectedReviewedBy) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reviewedBy: this.selectedReviewedBy,
            },
          };
        }

        await this.$store.dispatch("estimations/list", params);
        this.estimations = this.$store.getters["estimations/list"];
        this.total = this.$store.getters["estimations/listTotal"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteEstimate(estimate) {
      if (!this.canDeleteEstimate(estimate)) {
        return;
      }
      this.$emit("onDeleteEstimate", estimate);
    },

    viewEstimate(estimate) {
      if (this.canEditEstimate(estimate)) {
        this.$emit("onEditEstimate", estimate);
      }
      if (!this.canViewEstimate(estimate)) {
        return;
      }
      this.$emit("onViewEstimate", estimate);
    },

    editEstimate(estimate) {
      if (!this.canEditEstimate(estimate)) {
        return;
      }
      this.$emit("onEditEstimate", estimate);
    },

    addEstimate() {
      this.$emit("onAddEstimate");
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
