var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"inspection-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
      !_vm.hideOrganization
    )?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.vehicle.organization?.id,"filterable":true,"showAll":false,"disabled":_vm.disable},on:{"organizationChanged":(organizationId) => {
            _vm.reset();
            _vm.vehicle.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1 gallery"},[_c('gallery-selector',{attrs:{"label":_vm.$t('COMMON.PICTURES'),"defaultGallery":_vm.vehicle.gallery,"ressource_name":"vehicles","ressource_id":_vm.$idExist(_vm.vehicle.id) ? _vm.vehicle.id : '0',"field":"gallery","disabled":_vm.disable},on:{"galleryChanged":(gallery_urls) => {
          _vm.vehicle.gallery = gallery_urls;
          _vm.onFormChanged();
        }}})],1),_c('div',{staticClass:"inspection-form-title"},[_c('h3',[_vm._v(_vm._s(_vm.$t("VEHICLES.VEHICLE_IDENTIFICATION")))])]),_c('div',{staticClass:"inspection-form-divider"}),(
      false &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS) &&
      !_vm.hideOrganization
    )?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('location-selector',{attrs:{"location":_vm.vehicle?.allowedLocations[0]?.id ?? null,"filterable":true,"showAll":true,"multiple":false,"organization":_vm.vehicle.organization?.id,"disabled":_vm.disable},on:{"locationChanged":(location) => {
            _vm.console.log({ location });
            if (location) {
              _vm.vehicle.allowedLocations[0] = {
                id: location,
                type: 'locations',
              };
            } else {
              _vm.vehicle.allowedLocations = [];
            }
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1_2 has-button"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SKU_NUMBER')}`,"placeholder":_vm.$t('VEHICLES.SKU_NUMBER')}},[_c('vehicle-by-sku-selector',{attrs:{"filterOrganization":_vm.vehicle.organization?.id,"disabled":_vm.disable,"sku":_vm.vehicle.sku,"clearable":""},on:{"skuChanged":(sku) => {
            _vm.vehicle.sku = sku;
          },"vehicleSelected":(vehicleData) => {
            _vm.vehicle.id = `${vehicleData.id}`;
            _vm.vehicle.vin = vehicleData.vin;
            _vm.vehicle.make = vehicleData.make;
            _vm.vehicle.model = vehicleData.model;
            _vm.vehicle.series = vehicleData.series;
            _vm.vehicle.model_year = vehicleData.model_year;
            _vm.vehicle.style = vehicleData.style;
            _vm.vehicle.engine_description = vehicleData.engine_description;
            _vm.vehicle.fuel_type = vehicleData.fuel_type;
            _vm.vehicle.transmission = vehicleData.transmission;
            _vm.vehicle.drivetrain = vehicleData.drivetrain;
            _vm.vehicle.class_code = vehicleData.class_code;
            _vm.vehicle.class_name = vehicleData.class_name;
            _vm.vehicle.gallery = vehicleData.gallery || [];
            _vm.vehicle.registration_number = vehicleData.registration_number;
            _vm.vehicle.odometer = vehicleData.odometer ?? 0;
            _vm.vehicle.color = vehicleData.color;
            _vm.vehicle.sku = vehicleData.sku;
            _vm.vehicle.fuel_type = vehicleData.fuel_type;

            _vm.hasInProgressInspections(vehicleData.inspection_ids);

            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.sku}})],1),_c('div',{staticClass:"inspection-form-group width-1_2 has-button"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.VIN')}`,"placeholder":_vm.$t('VEHICLES.VIN')}},[_c('vehicle-api-by-vin-selector',{attrs:{"filterOrganization":_vm.vehicle.organization?.id,"disabled":_vm.disable,"vin":_vm.vehicle.vin,"clearable":""},on:{"vinChanged":(vin) => {
            _vm.vehicle.vin = vin;
          },"vehicleSelected":(vehicleData) => {
            _vm.vehicle.id = `${vehicleData.id}`;
            _vm.vehicle.vin = vehicleData.requested_vin;
            _vm.vehicle.make = vehicleData.make;
            _vm.vehicle.model = vehicleData.model;
            _vm.vehicle.series = vehicleData.series;
            _vm.vehicle.model_year = vehicleData.model_year;
            _vm.vehicle.style = vehicleData.style;
            _vm.vehicle.engine_description = vehicleData.engine_description;
            _vm.vehicle.fuel_type = vehicleData.fuel_type;
            _vm.vehicle.transmission = vehicleData.transmission;
            _vm.vehicle.drivetrain = vehicleData.drivetrain;
            _vm.vehicle.class_code = vehicleData.class_code;
            _vm.vehicle.class_name = vehicleData.class_name;
            _vm.vehicle.gallery = vehicleData.gallery || [];
            _vm.vehicle.registration_number = vehicleData.registration_number;
            _vm.vehicle.odometer = vehicleData.odometer ?? 0;
            _vm.vehicle.color = vehicleData.color;
            _vm.vehicle.sku = vehicleData.sku;
            _vm.vehicle.fuel_type = vehicleData.fuel_type;

            _vm.hasInProgressInspections(vehicleData.inspection_ids);

            _vm.hasInProgressEstimations(vehicleData.estimation_ids);

            _vm.onFormChanged();
          }}})],1)],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MODEL_YEAR')} (*)`,"placeholder":_vm.$t('VEHICLES.MODEL_YEAR'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('year-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.model_year},on:{"yearChanged":(year) => {
            _vm.vehicle.model_year = year;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.model_year}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MAKE')} (*)`,"placeholder":_vm.$t('VEHICLES.MAKE'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('make-selector',{attrs:{"year":_vm.vehicle.model_year,"disabled":_vm.disable,"filterable":true,"placeholder":_vm.$t('VEHICLES.MAKE'),"value":_vm.vehicle.make},on:{"makeChanged":(make) => {
            _vm.vehicle.make = make;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.make}})],1),_c('div',{staticClass:"inspection-form-group width-1_2"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.MODEL')} (*)`,"placeholder":_vm.$t('VEHICLES.MODEL'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('model-selector',{attrs:{"year":_vm.vehicle.model_year,"disabled":_vm.disable,"filterable":true,"make":_vm.vehicle.make,"value":_vm.vehicle.model},on:{"modelChanged":(model) => {
            _vm.vehicle.model = model;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.model}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.SERIES')}`,"placeholder":_vm.$t('VEHICLES.SERIES'),"disabled":_vm.disable}},[_c('serie-selector',{attrs:{"year":_vm.vehicle.model_year,"make":_vm.vehicle.make,"disabled":_vm.disable,"model":_vm.vehicle.model,"filterable":true,"value":_vm.vehicle.series},on:{"serieChanged":(serie) => {
            _vm.vehicle.series = serie;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.series}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.STYLE')}`,"placeholder":_vm.$t('VEHICLES.STYLE'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('style-selector',{attrs:{"year":_vm.vehicle.model_year,"make":_vm.vehicle.make,"model":_vm.vehicle.model,"serie":_vm.vehicle.serie,"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.style},on:{"styleChanged":(style) => {
            _vm.vehicle.style = style;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.style}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.ENGINE_DESCRIPTION')} `,"placeholder":_vm.$t('VEHICLES.ENGINE_DESCRIPTION'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.engine_description),callback:function ($$v) {_vm.$set(_vm.vehicle, "engine_description", $$v)},expression:"vehicle.engine_description"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.engine_description}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.FUEL_TYPE')} `,"placeholder":_vm.$t('VEHICLES.FUEL_TYPE'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.fuel_type),callback:function ($$v) {_vm.$set(_vm.vehicle, "fuel_type", $$v)},expression:"vehicle.fuel_type"}},[_c('fuel-type-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.fuel_type,"options":_vm.fuelTypes ?? []},on:{"fuelTypeChanged":(fuelType) => {
            _vm.vehicle.fuel_type = fuelType;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.fuel_type}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.CLASS_NAME')}`,"placeholder":_vm.$t('VEHICLES.CLASS_NAME'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('vehicle-class-code-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.class_code},on:{"classCodeChanged":(classCode) => {
            _vm.vehicle.class_code = classCode;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.class_code}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.REGISTRATION_NUMBER')} `,"placeholder":_vm.$t('VEHICLES.REGISTRATION_NUMBER'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.registration_number),callback:function ($$v) {_vm.$set(_vm.vehicle, "registration_number", $$v)},expression:"vehicle.registration_number"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.registration_number}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.COLOR')} `,"placeholder":_vm.$t('VEHICLES.COLOR'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.color),callback:function ($$v) {_vm.$set(_vm.vehicle, "color", $$v)},expression:"vehicle.color"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.color}})],1),(false)?_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.TRANSMISSION')} `,"placeholder":_vm.$t('VEHICLES.TRANSMISSION'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('transmission-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.transmission},on:{"transmissionChanged":(transmission) => {
            _vm.vehicle.transmission = transmission;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.transmission}})],1):_vm._e(),(false)?_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"label":`${_vm.$t('VEHICLES.DRIVETRAIN')} `,"placeholder":_vm.$t('VEHICLES.DRIVETRAIN'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}}},[_c('drivetrain-selector',{attrs:{"disabled":_vm.disable,"filterable":true,"value":_vm.vehicle.drivetrain},on:{"drivetrainChanged":(drivetrain) => {
            _vm.vehicle.drivetrain = drivetrain;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.drivetrain}})],1):_vm._e(),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{staticClass:"clear-input-number-arrows odometer",attrs:{"disabled":_vm.disable,"label":`${_vm.$t('VEHICLES.ODOMETER')} (*)`,"placeholder":_vm.$t('VEHICLES.ODOMETER'),"min":"0","type":"number"},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.odometer),callback:function ($$v) {_vm.$set(_vm.vehicle, "odometer", $$v)},expression:"vehicle.odometer"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.odometer}})],1),_c('div',{staticClass:"inspection-form-group width-1_4"},[_c('base-input',{attrs:{"disabled":_vm.disable,"label":`${_vm.$t('VEHICLES.ODOMETER_UNIT')} (*)`,"placeholder":_vm.$t('VEHICLES.ODOMETER_UNIT')},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.odometer),callback:function ($$v) {_vm.$set(_vm.vehicle, "odometer", $$v)},expression:"vehicle.odometer"}},[_c('el-select',{attrs:{"placeholder":_vm.$t('VEHICLES.ODOMETER_UNIT'),"disabled":_vm.disable},on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.vehicle.odometer_unit),callback:function ($$v) {_vm.$set(_vm.vehicle, "odometer_unit", $$v)},expression:"vehicle.odometer_unit"}},[_c('el-option',{attrs:{"value":"km","label":_vm.$t('ODOMETERS.KM')}}),_c('el-option',{attrs:{"value":"miles","label":_vm.$t('ODOMETERS.MILES')}})],1)],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.odometer_unit}})],1),(
      false &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_CUSTOMERS) &&
      _vm.showCustomer
    )?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CUSTOMERS')}`,"placeholder":_vm.$t('COMMON.CUSTOMERS')}},[_c('customer-selector',{attrs:{"allowNone":true,"customer":_vm.vehicle.customer?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.vehicle.organization?.id},on:{"customerChanged":(customerId, customer) => {
            _vm.vehicle.customer = customer;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.customer}})],1):_vm._e(),(
      false &&
      _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_USERS) &&
      _vm.showMecanic
    )?_c('div',{staticClass:"inspection-form-group width-1"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.MECANIC')}`,"placeholder":_vm.$t('COMMON.MECANIC')}},[_c('user-selector',{attrs:{"allowNone":true,"user":_vm.vehicle.mecanic?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.vehicle.organization?.id},on:{"userChanged":(userId, user) => {
            _vm.vehicle.mecanic = user;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.user}})],1):_vm._e(),_vm._t("submit-button",function(){return [_c('div',{staticClass:"inspection-form-group width-1 next-button"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading || _vm.disable}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.saveButtonText)+" ")])],1)]},{"loading":_vm.loading,"disable":_vm.disable})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }