<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ $objectDenomination(vehicle) }} -
          {{ $t("COMMON.INSPECTIONS") }}
        </span>
      </div>
      <inspection-list-table
        :filterVehicle="vehicle.id"
        @onAddInspection="addInspection"
        @onViewInspection="viewInspection"
        @onEditInspection="editInspection"
        @onDeleteInspection="viewInspection"
      />
    </div>
  </div>
</template>

<script>
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import InspectionListTable from "@/views/Pages/InspectproModule/InspectionManagement/partials/InspectionListTable.vue";

export default {
  name: "vehicle-view-inspections",

  components: { InspectionListTable },

  props: ["vehicle"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    viewInspection(inspection) {
      this.$router.push({
        name: "List Inspections",
        query: { id: inspection.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    editInspection(inspection) {
      this.$router.push({
        name: "List Inspections",
        query: { id: inspection.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    addInspection() {
      this.$router.push({
        name: "List Inspections",
        query: {
          action: QUERY_ACTIONS_ADD,
          vehicle_id: this.vehicle.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
