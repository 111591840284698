<template>
  <div class="d-flex">
    <div
      class="w-125px h-125px rounded-circle mr-3 overflow-hidden"
      :class="{
        'bg-light d-flex justify-content-center align-items-center': !image,
      }"
    >
      <img
        :src="image"
        alt="logo"
        class="w-100 h-100 object-cover"
        v-if="image"
      />
      <img v-else src="/img/add-image.svg" />
    </div>
    <div class="d-flex flex-column">
      <h3 class="mt-1">{{ title }}</h3>
      <slot name="second-title">
        <h4 class="text-muted">{{ secondTitle }}</h4>
      </slot>
      <div
        class="inspection-form-group next-button p-0 border-top-0 mt-2"
        v-if="showBtn"
      >
        <base-button
          class="btn btn-sm elite-submit"
          size="sm"
          type="button"
          @click.prevent="btnAction"
        >
          {{ btnLabel ?? $t("COMMON.UPDATE") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-object-ref-widget",

  props: {
    image: {
      type: String | null | undefined,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    secondTitle: {
      type: String,
      required: true,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    btnLabel: {
      type: String | null | undefined,
      default: null,
    },
    btnAction: {
      type: Function,
      default: "#",
    },
  },

  computed: {},
};
</script>

<style lang="scss" scoped>
.w-125px {
  width: 125px;
}
.h-125px {
  height: 125px;
}
.object-cover {
  object-fit: cover;
}
</style>
